.side-nav-wrapper {
  position: fixed;
  /* Fixed Sidebar (stay in place on scroll and position relative to viewport) */
  width: 5rem;
  height: 100vh;
  background-color: #23232e;
  transition: width 200ms ease;
  z-index: 2; }
  .side-nav-wrapper .side-nav-nar {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%; }
    .side-nav-wrapper .side-nav-nar .logo {
      text-align: center;
      color: #ececec;
      background-color: #141418;
      text-transform: uppercase;
      letter-spacing: 0.3ch;
      width: 100%; }
      .side-nav-wrapper .side-nav-nar .logo .link-text {
        margin-left: 1rem;
        font-weight: bold;
        font-size: 1.5rem; }
      .side-nav-wrapper .side-nav-nar .logo .nav-icon {
        transform: rotate(0deg);
        transition: transform 600ms; }
    .side-nav-wrapper .side-nav-nar:hover .logo .nav-icon {
      transform: rotate(-180deg); }
  .side-nav-wrapper:hover {
    width: 20rem; }
    .side-nav-wrapper:hover .side-nav-nar .link-text {
      display: block; }
  @media only screen and (min-width: 900px) {
    .side-nav-wrapper {
      top: 0;
      width: 5rem;
      height: 100vh; }
      .side-nav-wrapper:hover {
        width: 20rem; }
        .side-nav-wrapper:hover .link-text {
          display: inline; } }
  @media only screen and (max-width: 800px) {
    .side-nav-wrapper {
      bottom: 0;
      width: 100vw;
      height: 5rem;
      z-index: 2; }
      .side-nav-wrapper .logo {
        display: none; }
      .side-nav-wrapper .side-nav-nar {
        flex-direction: row;
        justify-content: space-around; }
      .side-nav-wrapper .sidebar-nav-link {
        justify-content: center; } }

.styled-nav-item {
  width: 100%;
  /* width must be same size as NavBar to center */
  text-align: center;
  /* Aligns <a> inside of NavIcon div */ }
  .styled-nav-item:hover > .submenu {
    display: block;
    transition: all 2s linear; }
  .styled-nav-item .nav-link {
    padding: 0; }
  .styled-nav-item:hover {
    background: #141418;
    color: #ff7eee; }
  .styled-nav-item:last-child {
    margin-top: auto; }
  .styled-nav-item .sidebar-nav-link {
    display: flex;
    align-items: center;
    height: 5rem;
    width: 16rem;
    color: #6649b8;
    text-decoration: none;
    filter: grayscale(100%) opacity(0.7);
    transition: 600ms; }
    .styled-nav-item .sidebar-nav-link:hover {
      text-decoration: none;
      filter: none; }
    .styled-nav-item .sidebar-nav-link .link-text {
      display: none;
      margin-left: 0;
      font-weight: 500;
      font-size: 1.2rem; }
    .styled-nav-item .sidebar-nav-link .nav-icon,
    .styled-nav-item .sidebar-nav-link .nav-image {
      min-width: 2rem;
      margin: 0 1.5rem;
      height: 2rem;
      color: #6649b8;
      transition: 600ms; }
      .styled-nav-item .sidebar-nav-link .nav-icon:hover,
      .styled-nav-item .sidebar-nav-link .nav-image:hover {
        color: #6649b8; }
    @media only screen and (max-width: 800px) {
      .styled-nav-item .sidebar-nav-link {
        width: auto; } }
  @media only screen and (max-width: 800px) {
    .styled-nav-item {
      width: auto; } }
  .styled-nav-item .submenu {
    display: none;
    transition: all 2s linear;
    padding-left: 0; }
    @media only screen and (max-width: 800px) {
      .styled-nav-item .submenu {
        display: none; } }
    .styled-nav-item .submenu .styled-nav-item .sidebar-nav-link {
      height: 3rem;
      border-bottom: 1px solid #23232e; }
      .styled-nav-item .submenu .styled-nav-item .sidebar-nav-link .link-text {
        font-size: 1rem; }
      .styled-nav-item .submenu .styled-nav-item .sidebar-nav-link .nav-icon {
        max-width: 1.5rem;
        min-width: 1.5rem; }
    .styled-nav-item .submenu .submenu-nav-link {
      width: 20rem;
      background: #574b90;
      color: #eee;
      text-align: left;
      border-bottom: 1px solid #000;
      font-weight: 500;
      padding: 10px 0 10px 25px;
      filter: grayscale(30%) opacity(0.7); }
      .styled-nav-item .submenu .submenu-nav-link:hover {
        filter: grayscale(0%) opacity(1); }
