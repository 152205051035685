@import url(https://fonts.googleapis.com/css2?family=Abel&family=Roboto:wght@100&display=swap);
#chatbot-avanti .chatbot-avanti :root {
  font-size: 12px; }

#chatbot-avanti html {
  background-color: transparent; }

#chatbot-avanti .hidden {
  display: none !important; }

#chatbot-avanti .bodyChat {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  background-color: transparent;
  padding: 0; }
  #chatbot-avanti .bodyChat::-webkit-scrollbar {
    width: 0.25rem; }
    #chatbot-avanti .bodyChat::-webkit-scrollbar-track {
      background: #1e1e24; }
    #chatbot-avanti .bodyChat::-webkit-scrollbar-thumb {
      background: #6649b8; }

#chatbot-avanti .main-wrapper {
  font-family: 'Abel', sans-serif;
  font-weight: 500; }
  #chatbot-avanti .main-wrapper h1 {
    text-align: center; }
  #chatbot-avanti .main-wrapper .cards-wrapper .card {
    box-shadow: 0 0 20px 4px #555558; }
  #chatbot-avanti .main-wrapper .card,
  #chatbot-avanti .main-wrapper .table-responsive {
    box-shadow: 0 0 40px 4px #111118; }
  #chatbot-avanti .main-wrapper button.btn-primary,
  #chatbot-avanti .main-wrapper a.btn-primary {
    background-color: #574b90;
    border: solid 1px #6649b8; }
    #chatbot-avanti .main-wrapper button.btn-primary:hover,
    #chatbot-avanti .main-wrapper a.btn-primary:hover {
      -webkit-filter: grayscale(30%) opacity(0.7);
              filter: grayscale(30%) opacity(0.7); }

#chatbot-avanti .copy-right {
  position: fixed;
  width: 130px;
  right: 20px;
  bottom: 5px;
  text-align: center;
  padding: 6px 10px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.1);
  font-size: 0.6rem;
  font-weight: bold;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  border: 1px solid #bbb;
  color: #eee;
  text-shadow: 0px 1px 1px black; }

#chatbot-avanti code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

@media only screen and (min-width: 600px) {
  #chatbot-avanti .desktop-only {
    display: block !important; } }

@media only screen and (max-width: 800px) {
  #chatbot-avanti .desktop-only {
    display: none !important; } }

@media only screen and (min-width: 600px) {
  #chatbot-avanti .mobile-only {
    display: none !important; } }

@media only screen and (max-width: 800px) {
  #chatbot-avanti .mobile-only {
    display: block !important; } }

#chatbot-avanti ul {
  list-style-type: disc; }

#chatbot-avanti .form-custom-group {
  display: flex;
  margin-bottom: 1rem;
  width: 100%; }
  #chatbot-avanti .form-custom-group .label-name {
    flex: 20% 0; }
  #chatbot-avanti .form-custom-group .custom-input {
    flex: 0 1 60%; }

#chatbot-avanti div.form-custom-group-i {
  width: 50%;
  display: block;
  margin-bottom: 1rem;
  height: 3.5rem; }
  #chatbot-avanti div.form-custom-group-i .custom-input.form-control {
    width: 100%;
    height: 100%;
    padding-top: 1rem;
    border: none; }
    #chatbot-avanti div.form-custom-group-i .custom-input.form-control:focus {
      z-index: 0; }
  #chatbot-avanti div.form-custom-group-i .label-name {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 2;
    margin: 0;
    pointer-events: none;
    border-bottom: 1px solid black;
    font-size: 1rem;
    font-weight: 500; }
    #chatbot-avanti div.form-custom-group-i .label-name::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      height: 100%;
      width: 100%;
      border-bottom: 2px solid #5fa8d3; }

#chatbot-avanti .main-wrapper h1 {
  margin-top: 3rem; }

#chatbot-avanti .alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  #chatbot-avanti .alert.danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb; }
  #chatbot-avanti .alert.success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb; }

#chatbot-avanti .App {
  text-align: center;
}

#chatbot-avanti .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  #chatbot-avanti .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

#chatbot-avanti .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#chatbot-avanti .App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.home h1 {
  font-size: 2rem;
  text-align: center; }

.home .home-container {
  width: 90%;
  margin: 0 auto;
  border-radius: 4px;
  min-height: 5rem;
  margin-top: 1rem; }

.home .user-list_wrapper {
  width: 60%;
  margin: 0 auto; }

.custom-table_wrapper .custom-table_container {
  margin-bottom: 1rem; }

.custom-table_wrapper .table-link-title {
  color: #000; }

.custom-table_wrapper .table-icon {
  margin-right: 5px; }

.custom-table_wrapper .custom-table_subtable {
  margin: 1rem 0 0 0; }

.custom-table_wrapper .custom-table_head {
  border-radius: 2px 0 2px 0;
  -moz-border-radius: 2px 0 2px 0;
  -webkit-border-radius: 2px 0 2px 0; }

.custom-table_wrapper .custom-table_header {
  background-color: #23232e; }
  .custom-table_wrapper .custom-table_header .MuiTableCell-root {
    color: #ececec; }

.custom-table_wrapper .MuiTableCell-root {
  padding: 0.5rem; }

.form-custom-group {
  display: flex;
  justify-content: space-between; }
  .form-custom-group .label-name {
    display: none; }
  .form-custom-group .custom-input {
    width: 100%; }
  .form-custom-group.rs-100 {
    max-width: 98%; }
  .form-custom-group.rs-60 {
    max-width: 58%;
    margin-right: 2%;
    float: left; }
  .form-custom-group.rs-50 {
    max-width: 48%;
    margin-right: 2%;
    float: left; }
  .form-custom-group.rs-30 {
    max-width: 28%;
    margin-right: 2%;
    float: left; }
  .form-custom-group.rs-25 {
    max-width: 23%;
    margin-right: 2%;
    float: left; }
  .form-custom-group.rs-100 .custom-input, .form-custom-group.rs-60 .custom-input, .form-custom-group.rs-50 .custom-input, .form-custom-group.rs-30 .custom-input, .form-custom-group.rs-25 .custom-input {
    flex: 0 1 100%; }
  .form-custom-group .MuiButton-containedPrimary {
    background-color: #3f51b5; }

.button-wrapper {
  display: flex;
  width: 100%;
  justify-content: center; }
  .button-wrapper .MuiButton-outlinedPrimary {
    color: white;
    background-color: #3f51b5;
    border: none; }
    .button-wrapper .MuiButton-outlinedPrimary:hover {
      background-color: #b6b6b6;
      border: none; }
  .button-wrapper .MuiButton-outlinedSecondary {
    color: white;
    background-color: #23232e;
    border: none; }
    .button-wrapper .MuiButton-outlinedSecondary:hover {
      background-color: #555558;
      border: none; }

.PrivateSwitchBase-root-1 {
  padding: 1px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #2196F3 !important; }

.p-rating .p-rating-icon.pi-star {
  color: gold !important; }

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: gold !important; }

.p-rating .p-rating-icon {
  font-size: 1.36rem !important; }

.MuiFormControlLabel-root {
  margin-left: -6px !important; }

.MuiTypography-body1 {
  font-family: 'Abel' !important; }

.center {
  text-align: center;
  margin-top: 7px; }

.whiteBackground {
  background-color: white; }

#chatbot-avanti {
  /* .fixedPosition {
  position: fixed;

  top: 374px;
  right: 190px;
} */
  /* .positionSpinner {
  position: fixed;
  top: 348px;
  right: 117px;
} */
  /* #chatbot-avanti {
  .rcw-widget-container {
    height: auto;
    width: auto;

  }

} */
  /*  .chat-opened {
  #chatbot-avanti {
    .rcw-widget-container {
      height: 82vh;
     
    }

  }

  }  */
  /* ALCALDIA DE BOGOTA 1029*/
  /*superfinaniera */
  /* 
  .chat-opened #chatbot-avanti{

  .chatbot-avanti-1023 {
    .rcw-widget-container {
      height: 77vh;
  }

  }

  }
 */ }
  #chatbot-avanti .chat-demo-wrapper a {
    color: #000;
    font-weight: bold; }
  #chatbot-avanti .chat-demo-wrapper .button-wrapper {
    justify-content: flex-start;
    margin-left: 1rem; }
  #chatbot-avanti .chat-demo-wrapper .MuiInput-underline:before {
    border-bottom: 0; }
  #chatbot-avanti .chat-demo-wrapper .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0; }
  #chatbot-avanti .chat-demo-wrapper .rcw-conversation-container .rcw-header {
    padding: 10px;
    background-color: #fff;
    border-bottom: 1px solid;
    color: #1778bd; }
    #chatbot-avanti .chat-demo-wrapper .rcw-conversation-container .rcw-header .rcw-title {
      display: none; }
  #chatbot-avanti .chat-demo-wrapper .radioTerms .MuiFormControl-root .MuiFormGroup-root {
    display: flex;
    flex-direction: row; }
  #chatbot-avanti .chat-demo-wrapper .chat-image-header {
    max-width: 51%; }
  #chatbot-avanti .chat-demo-wrapper .checkbox .label-name {
    display: none; }
  #chatbot-avanti .chat-demo-wrapper .form_wrapper {
    padding: 0;
    padding-bottom: 0;
    margin-bottom: 0; }
  #chatbot-avanti .chat-demo-wrapper .form_container .field-container {
    padding: 15px;
    background-color: #fff;
    padding-top: 0;
    font-size: 14px;
    margin-bottom: 0; }
  #chatbot-avanti .chat-demo-wrapper .rcw-close-launcher {
    max-width: 73px; }
  #chatbot-avanti .chat-demo-wrapper .MuiInputBase-input {
    border: 2px solid #ddd;
    border-radius: 4px; }
  #chatbot-avanti .chat-demo-wrapper .button-wrapper .MuiButton-outlinedPrimary {
    background-color: #1778bd;
    background-color: #1778bd;
    border-radius: 20px;
    font-weight: bold;
    font-size: 0.8rem;
    margin-bottom: 5px; }
  #chatbot-avanti .chat-demo-wrapper .Mui-error .MuiInputBase-input {
    border-color: red; }
  #chatbot-avanti .chat-demo-wrapper .MuiInputLabel-formControl {
    font-size: 13px;
    padding-left: 5px; }
  #chatbot-avanti .chat-demo-wrapper form#form-conversationForm {
    height: 50vh;
    max-height: 350px;
    padding-left: 20px;
    padding-top: 5px;
    padding-right: 20px; }
  #chatbot-avanti .chat-demo-wrapper .form-container {
    background-color: transparent;
    bottom: 0;
    display: none;
    flex-direction: column;
    margin: 0 20px 20px 0;
    max-width: 370px;
    position: fixed;
    right: 0;
    width: 90vw;
    z-index: 10000;
    border-radius: 10px 10px 0 0; }
    #chatbot-avanti .chat-demo-wrapper .form-container p {
      background: #fff;
      margin: 0;
      text-align: center; }
    #chatbot-avanti .chat-demo-wrapper .form-container.zindex2000 {
      z-index: 20000;
      display: flex;
      background-color: #fff;
      box-shadow: 0 2px 10px 1px #b5b5b5; }
    #chatbot-avanti .chat-demo-wrapper .form-container .form-title {
      background-color: #fff;
      border-radius: 10px 10px 0 0;
      color: black;
      display: flex;
      flex-direction: row;
      text-align: center;
      font-size: 24px;
      font-weight: 400;
      margin: 0;
      padding: 10px;
      justify-content: space-between; }
    #chatbot-avanti .chat-demo-wrapper .form-container .form-sub-title b {
      color: #1778bd; }
    #chatbot-avanti .chat-demo-wrapper .form-container .form-sub-title {
      padding: 15px;
      font-size: 14px;
      padding-top: 0; }
    #chatbot-avanti .chat-demo-wrapper .form-container .icon-title {
      float: left;
      color: #1778bd;
      font-size: 2rem; }
    #chatbot-avanti .chat-demo-wrapper .form-container .MuiTypography-body1 {
      font-size: 14px; }
    #chatbot-avanti .chat-demo-wrapper .form-container .fake-button {
      border: 0;
      height: 80px;
      width: 100%;
      cursor: pointer;
      z-index: 20000; }
      #chatbot-avanti .chat-demo-wrapper .form-container .fake-button .rcw-fake-close-button {
        display: none; }
    #chatbot-avanti .chat-demo-wrapper .form-container .form-conversation {
      background-color: #fff; }
    #chatbot-avanti .chat-demo-wrapper .form-container .fake-field {
      align-items: center;
      display: flex;
      background-color: #f4f7f9;
      height: 45px;
      padding: 5px;
      border-radius: 0 0 10px 10px; }
  @media only screen and (max-width: 800px) {
    #chatbot-avanti .chat-demo-wrapper .form-container {
      width: 100%;
      max-width: 100%;
      margin: 0;
      height: 100%; }
      #chatbot-avanti .chat-demo-wrapper .form-container .fake-button {
        position: relative;
        margin-top: 10px; }
        #chatbot-avanti .chat-demo-wrapper .form-container .fake-button .rcw-fake-close-button {
          display: block;
          position: absolute;
          width: 60px;
          height: 60px;
          left: auto;
          bottom: 15px;
          right: 20px;
          background-color: #35cce6;
          border-radius: 30px;
          border: none; }
          #chatbot-avanti .chat-demo-wrapper .form-container .fake-button .rcw-fake-close-button .rcw-close {
            max-width: 40px;
            max-height: 40px;
            padding: 5px; }
      #chatbot-avanti .chat-demo-wrapper .form-container .fake-field {
        display: none; }
      #chatbot-avanti .chat-demo-wrapper .form-container .form_wrapper {
        height: 100%;
        background: #fff; } }
  #chatbot-avanti .chat-demo-wrapper .form_container .material-field {
    min-height: 3rem;
    margin-top: 0.5rem; }
  #chatbot-avanti .big-text {
    font-size: larger; }
  #chatbot-avanti .fixed-margin {
    margin-bottom: 2px; }
  #chatbot-avanti .scroll {
    overflow-y: scroll; }
  #chatbot-avanti .MuiFormGroup-root {
    display: block; }
  #chatbot-avanti #formInicio .label-name {
    display: none; }
  #chatbot-avanti #formInicio .radioTerms .label-name {
    display: block; }
  #chatbot-avanti #formInicio .form-sub-title {
    padding-top: 15px;
    padding-bottom: 2px; }
  #chatbot-avanti .button-wrapper .MuiButton-outlinedPrimary:hover {
    background-color: #990000 !important; }
  #chatbot-avanti #formInicio .button-wrapper {
    margin-top: 0 !important;
    margin-left: 0 !important;
    justify-content: center; }
  #chatbot-avanti .jss8 {
    padding: 1px !important; }
  #chatbot-avanti .container-disabled input {
    visibility: hidden;
    pointer-events: none;
    cursor: not-allowed; }
  #chatbot-avanti .rcw-message img {
    max-width: 200px; }
  #chatbot-avanti img.lb-img {
    cursor: pointer !important; }
  #chatbot-avanti img.lb-img:not([style="transform: translate3d(0px, 0px, 0px) scale(1) rotate(0deg); cursor: unset; transition: all 0.1s ease 0s;"]) {
    position: fixed;
    left: -100%;
    top: 50px;
    bottom: auto;
    right: auto;
    max-width: 300px;
    -webkit-transform: translate3d(0, 0, 0) scale(3) !important;
            transform: translate3d(0, 0, 0) scale(3) !important;
    z-index: 200; }
  #chatbot-avanti .rcw-widget-container .rcw-new-message {
    padding-left: 42px;
    padding-right: 38px;
    height: 35px;
    border-radius: 10px; }
  @media only screen and (max-width: 800px) {
    #chatbot-avanti .rcw-widget-container {
      width: 95vw;
      margin: 10px;
      margin-bottom: 90px; } }
  #chatbot-avanti .rcw-message .wmp-container,
  #chatbot-avanti .rcw-message video {
    max-width: 260px !important;
    max-height: 260px !important; }
  #chatbot-avanti .rcw-message .rcw-link {
    font-size: 0; }
    #chatbot-avanti .rcw-message .rcw-link:before {
      content: "Descargar";
      font-size: 12px;
      border: 1px solid #aaa;
      border-radius: 12px;
      background: #ccc;
      padding: 4px 8px; }
  #chatbot-avanti .rcw-message .rhap_container {
    max-width: 260px !important; }
  #chatbot-avanti .container-disabled input {
    visibility: hidden;
    pointer-events: none;
    cursor: not-allowed; }
  #chatbot-avanti .reply-wrapper {
    background-color: #f4f7f9;
    border-radius: 10px;
    max-width: 80%;
    padding: 10px;
    font-family: "Abel", sans-serif !important;
    font-size: 12px !important; }
    #chatbot-avanti .reply-wrapper .caption-title {
      margin-top: 0;
      margin-bottom: 8px;
      font-weight: bold; }
    #chatbot-avanti .reply-wrapper .caption-subtitle {
      margin-bottom: 5px; }
    #chatbot-avanti .reply-wrapper .options {
      margin-top: 5px; }
      #chatbot-avanti .reply-wrapper .options .option-item {
        font-size: 12px;
        border: 1px solid #aaa;
        border-radius: 12px;
        background: #ccc;
        padding: 4px 8px;
        margin-bottom: 10px;
        max-width: 120px;
        text-align: center;
        font-weight: bold;
        cursor: pointer; }
  #chatbot-avanti .rcw-sender {
    background: #e8e3e3;
    padding-top: 0px;
    padding-bottom: 0px; }
  #chatbot-avanti p {
    font-family: "Abel", sans-serif !important; }
  @media only screen and (max-width: 800px) {
    #chatbot-avanti .captcha-container {
      background-color: white; } }
  #chatbot-avanti .captchaForm {
    padding: 2rem;
    background-color: white;
    height: 30rem;
    padding-top: 3rem; }
    @media only screen and (max-width: 800px) {
      #chatbot-avanti .captchaForm {
        height: 37rem; } }
  #chatbot-avanti .rcw-send:hover {
    background-color: #cdd1cd; }
  #chatbot-avanti .rcw-send:active {
    background-color: #cacfca;
    box-shadow: 0 5px #b4b3b3;
    -webkit-transform: translateY(4px);
            transform: translateY(4px); }
  #chatbot-avanti .rcw-send {
    border-radius: 7px; }
  #chatbot-avanti .sound-wave {
    max-width: 283px;
    max-height: 34px;
    width: 283px;
    height: 34px;
    padding: 0;
    position: fixed;
    left: auto;
    top: auto;
    bottom: 97px;
    right: 97px;
    z-index: 10001;
    display: none;
    border-radius: 11px; }
    @media only screen and (max-width: 800px) {
      #chatbot-avanti .sound-wave {
        bottom: 5px;
        right: auto;
        left: 11px;
        width: 300px; } }
  #chatbot-avanti .attach-widget-microphone:hover {
    background-color: #cdd1cd; }
  #chatbot-avanti .attach-widget-send-audio {
    max-width: 30px;
    max-height: 30px;
    width: 30px;
    height: 30px;
    padding: 0;
    position: fixed;
    left: auto;
    top: auto;
    bottom: 97px;
    right: 35px;
    z-index: 10000;
    font-size: 14px;
    min-width: 0;
    display: none; }
    @media only screen and (max-width: 800px) {
      #chatbot-avanti .attach-widget-send-audio {
        bottom: 9px;
        right: auto;
        left: 335px;
        z-index: 10001; } }
  #chatbot-avanti .attach-widget-microphone {
    max-width: 30px;
    max-height: 30px;
    width: 30px;
    height: 30px;
    padding: 0;
    position: fixed;
    left: auto;
    top: auto;
    bottom: 97px;
    right: 64px;
    z-index: 10000;
    font-size: 14px;
    min-width: 0;
    display: none; }
    @media only screen and (max-width: 800px) {
      #chatbot-avanti .attach-widget-microphone {
        bottom: 8px;
        right: auto;
        left: 306px;
        z-index: 10001; } }
  #chatbot-avanti .attach-widget-file {
    max-width: 30px;
    max-height: 30px;
    width: 30px;
    height: 30px;
    padding: 0;
    /*    position: fixed;
    left: auto;
    top: auto;
    bottom: 96px;
    right: 348px;
*/
    z-index: 10000;
    font-size: 14px;
    cursor: pointer;
    min-width: 0;
    display: none; }
    #chatbot-avanti .attach-widget-file .p-button-label {
      font-size: 0; }
    #chatbot-avanti .attach-widget-file .p-button-icon {
      margin: 0; }
    #chatbot-avanti .attach-widget-file .p-button.p-fileupload-choose {
      padding: 5px; }
    #chatbot-avanti .attach-widget-file .p-button.p-fileupload-choose {
      background-color: #ccc;
      border: none; }
      #chatbot-avanti .attach-widget-file .p-button.p-fileupload-choose:hover {
        background-color: #999; }
    @media only screen and (max-width: 800px) {
      #chatbot-avanti .attach-widget-file {
        bottom: 5px;
        right: auto;
        left: 10px; } }
  .chat-opened #chatbot-avanti .container-disabled .attach-widget-file {
    display: none; }
  .chat-opened #chatbot-avanti .container-disabled .attach-widget-microphone {
    display: none; }
  .chat-opened #chatbot-avanti .attach-widget-file {
    display: block; }
  .chat-opened #chatbot-avanti .attach-widget-microphone {
    display: block; }
  #chatbot-avanti .loading-layout {
    width: 90vw;
    height: 57vh;
    background-color: #000;
    opacity: 0.5;
    z-index: 10002;
    position: fixed;
    right: 0;
    flex-direction: column;
    max-width: 370px;
    bottom: 70px;
    margin: 0 20px 20px 0; }
    @media only screen and (max-width: 800px) {
      #chatbot-avanti .loading-layout {
        margin: 0;
        width: 100vw;
        max-width: 100vw;
        height: 70vh;
        bottom: 45px; } }
    #chatbot-avanti .loading-layout .circular-message-loading {
      left: 20vw;
      top: 20vh;
      position: absolute; }
      @media only screen and (max-width: 800px) {
        #chatbot-avanti .loading-layout .circular-message-loading {
          left: 45vw;
          top: 30vh; } }
    #chatbot-avanti .loading-layout .spinner-text {
      left: 19vw;
      top: 29vh;
      position: absolute;
      font-size: 15px;
      color: #fff; }
      @media only screen and (max-width: 800px) {
        #chatbot-avanti .loading-layout .spinner-text {
          left: 45vw;
          top: 30vh;
          left: 43vw;
          top: 37vh; } }
  #chatbot-avanti .rcw-client.client-message {
    background-color: #a3eaf7;
    border-radius: 10px;
    padding: 15px;
    max-width: 215px;
    text-align: left; }
  #chatbot-avanti .client-message .wmp-container,
  #chatbot-avanti .client-message video,
  #chatbot-avanti .client-message .wmp-title-container {
    max-width: 180px !important;
    max-height: 200px !important; }
  #chatbot-avanti .panelReply .p-panel-content {
    padding: 0.5rem !important; }
  #chatbot-avanti .panelReply .p-panel-title {
    font-family: 'Abel';
    font-weight: bold;
    font-size: 1.2rem; }
  #chatbot-avanti .panelReply .p-panel-header {
    justify-content: normal !important;
    padding-right: 0rem; }
  #chatbot-avanti .list-container {
    border-radius: 8px 8px 0 0;
    font-family: "Abel", sans-serif !important;
    font-size: 13px !important;
    line-height: 1em !important; }
    #chatbot-avanti .list-container .caption {
      padding: 5px;
      margin-bottom: 5px;
      border-radius: 8px 8px 0 0;
      position: relative;
      background: #333; }
      #chatbot-avanti .list-container .caption.absolute-layout {
        min-height: 95px; }
      #chatbot-avanti .list-container .caption .header-image,
      #chatbot-avanti .list-container .caption .gray-layout {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        border-radius: 8px 8px 0 0; }
      #chatbot-avanti .list-container .caption .gray-layout {
        background: #000;
        opacity: 0.6;
        display: none; }
        #chatbot-avanti .list-container .caption .gray-layout.absolute-layout {
          display: block; }
      #chatbot-avanti .list-container .caption .caption-title {
        font-weight: bold;
        color: #eee;
        text-shadow: 1px 1px #000; }
      #chatbot-avanti .list-container .caption .close-caption {
        top: auto;
        bottom: 5px; }
        #chatbot-avanti .list-container .caption .close-caption.absolute-layout {
          position: absolute; }
      #chatbot-avanti .list-container .caption .caption-subtitle {
        color: #eee;
        text-shadow: 1px 1px #000; }
    #chatbot-avanti .list-container .caption-subtitle {
      width: 100%;
      text-align: left; }
    #chatbot-avanti .list-container .list-item {
      padding: 5px;
      display: flex; }
      #chatbot-avanti .list-container .list-item .right-item {
        display: flex;
        justify-content: center;
        align-items: center; }
        #chatbot-avanti .list-container .list-item .right-item img {
          border-radius: 4px; }
      #chatbot-avanti .list-container .list-item .option-item {
        margin-top: 0.4rem;
        max-width: 80%;
        border: 1px solid #10346e;
        border-radius: 4px;
        padding: 2px;
        color: #10346e;
        cursor: pointer;
        text-align: center; }
        #chatbot-avanti .list-container .list-item .option-item:hover {
          background-color: #10346e;
          color: #fff;
          font-weight: bold; }
      #chatbot-avanti .list-container .list-item .left-item {
        width: 100%; }
      #chatbot-avanti .list-container .list-item .right-item {
        width: 30%;
        border-radius: 4px; }
        #chatbot-avanti .list-container .list-item .right-item .item-image {
          width: 100%; }
      #chatbot-avanti .list-container .list-item .item-title {
        font-weight: bold;
        color: #000; }
      #chatbot-avanti .list-container .list-item .item-subtitle {
        color: #444; }
  #chatbot-avanti .rcw-response .rcw-message-text {
    max-width: 80%; }
  #chatbot-avanti .encuestaStyles .MuiFormGroup-root {
    flex-direction: initial !important; }
  #chatbot-avanti .encuestaStyles .MuiRadio-root {
    padding: 2px !important; }
  #chatbot-avanti .encuestaStyles .form-title {
    display: inline !important;
    display: initial !important;
    font-weight: 600 !important; }
  #chatbot-avanti .encuestaStyles .subtitleStyle {
    font-size: 15px !important; }
  #chatbot-avanti .main-wrapper {
    z-index: 3000;
    position: fixed; }
  #chatbot-avanti .richtext-message {
    background-color: #f4f7f9;
    border-radius: 10px;
    padding: 15px;
    max-width: 90%;
    text-align: left; }
  #chatbot-avanti .rcw-message p {
    margin-top: 0; }
  @media only screen and (max-width: 380px) {
    #chatbot-avanti #chatbot-avanti.chaticoGAB .rcw-launcher {
      right: 35px; } }
  #chatbot-avanti.chaticoGobiernoGov .rcw-widget-container {
    bottom: 10vh;
    margin: 0 25px 20px 0; }
  #chatbot-avanti .chatbot-avanti-1029 {
    /* .rcw-messages-container::-webkit-scrollbar {
    display: none;
} */
    /* Hide scrollbar for IE, Edge and Firefox */
    /* .rcw-messages-container {
    -ms-overflow-style: none;
 
    scrollbar-width: none;
   
} */ }
    #chatbot-avanti .chatbot-avanti-1029 .captchaForm {
      height: 24rem; }
    #chatbot-avanti .chatbot-avanti-1029 .positionSpinner {
      display: flex;
      justify-content: center;
      position: relative;
      bottom: 22rem; }
    #chatbot-avanti .chatbot-avanti-1029 .titleSubtitle {
      width: 100%;
      margin-top: 1.6px; }
      #chatbot-avanti .chatbot-avanti-1029 .titleSubtitle .subtitle {
        font-size: 17.6px;
        font-family: 'abel';
        width: 97%; }
    #chatbot-avanti .chatbot-avanti-1029 .pi-plus:before {
      content: "\002B" !important; }
    #chatbot-avanti .chatbot-avanti-1029 .pi-chevron-down:before {
      content: "\25BC" !important; }
    #chatbot-avanti .chatbot-avanti-1029 .pi-chevron-up:before {
      content: "\25B2" !important; }
    #chatbot-avanti .chatbot-avanti-1029 .fixedPosition {
      position: relative;
      bottom: 5rem;
      left: 7rem;
      justify-content: center;
      display: flex !important; }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .attach-widget-file {
      color: rgba(215, 34, 34, 0.768627);
      font-size: 1.2rem;
      font-weight: bold; }
      #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .attach-widget-file svg {
        font-size: 2rem; }
      @media only screen and (max-width: 800px) {
        #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .attach-widget-file {
          bottom: 18px;
          left: 15px; } }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .attach-widget-microphone {
      height: 23px;
      right: 73px;
      display: none; }
      #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .attach-widget-microphone .MuiSvgIcon-root {
        fill: rgba(215, 34, 34, 0.768627); }
      @media only screen and (max-width: 800px) {
        #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .attach-widget-microphone {
          bottom: 19px;
          left: 324px; } }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .sound-wave {
      width: 275px;
      height: 29px;
      bottom: 91px; }
      @media only screen and (max-width: 800px) {
        #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .sound-wave {
          bottom: 13px;
          left: 43px; } }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .attach-widget-send-audio .MuiSvgIcon-root {
      fill: rgba(215, 34, 34, 0.768627);
      font-size: 25px;
      height: 3.5rem; }
    @media only screen and (max-width: 800px) {
      #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .attach-widget-send-audio {
        bottom: 19px;
        left: 360px; } }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .rcw-client.client-message {
      background-color: #fafafa;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.54) 0px 1px 3px -1px; }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .rcw-sender {
      height: 55px;
      background: white; }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .rcw-widget-container .rcw-new-message {
      height: 45px;
      border-width: 2px;
      border-style: groove;
      border-color: #ff00008f;
      padding-left: 45px;
      padding-right: 34px;
      border-radius: 22px;
      font-family: sans-serif;
      font-size: 12px; }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .rcw-avatar {
      min-width: 15px;
      margin-right: 4px;
      width: 30px;
      height: 30px; }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .form-container {
      background-color: #fff; }
      #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .form-container .fake-field {
        background-color: #fff; }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .form-container .fake-button .rcw-fake-close-button {
      background-color: #d82200; }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .form-container .form-title {
      background-color: #d82200;
      display: flex;
      justify-content: center;
      min-height: 150px;
      align-items: center;
      border-radius: 1vmin 1vmin 3vmin 3vmin; }
      #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .form-container .form-title .icon-title {
        display: none; }
      #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .form-container .form-title .image-title {
        max-height: 80px; }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .form-container .fake-button .rcw-fake-close-button {
      display: block;
      position: absolute;
      width: 60px;
      height: 60px;
      left: auto;
      bottom: 15px;
      right: 20px;
      border-radius: 30px;
      border: none; }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .form-container .rcw-fake-close-button .rcw-close {
      max-width: 40px;
      max-height: 40px;
      padding: 5px; }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .rcw-launcher {
      background-color: rgba(0, 0, 0, 0);
      width: 70px;
      height: 70px;
      /* height: 70px;
        width: 70px; */
      padding: 0px 0px 0px 0px;
      box-shadow: none; }
      #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .rcw-launcher img {
        width: 73px; }
      @media only screen and (max-width: 800px) {
        #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .rcw-launcher {
          width: 60px !important;
          height: 60px !important;
          background-color: rgba(0, 0, 0, 0); } }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .captchaForm button {
      background-color: #d82200;
      min-width: 180px;
      border-color: #d82200; }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .rcw-picker-btn,
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .rcw-send {
      /* background: transparent; */
      border-radius: 15px;
      border: 0;
      cursor: pointer;
      height: 45px;
      border-width: 2px;
      border-style: groove;
      border-color: #ff00008f; }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .chat-image-header {
      max-height: 80px;
      margin: 0;
      margin: initial; }
    #chatbot-avanti .chatbot-avanti-1029 .rcw-messages-container {
      height: 56vh; }
      @media only screen and (max-width: 800px) {
        #chatbot-avanti .chatbot-avanti-1029 .rcw-messages-container {
          height: 100%; } }
    #chatbot-avanti .chatbot-avanti-1029 .rcw-badge {
      display: none; }
    #chatbot-avanti .chatbot-avanti-1029 .encuestaStyles {
      border-radius: 11px 10px 10px 10px !important; }
      #chatbot-avanti .chatbot-avanti-1029 .encuestaStyles .form-title {
        border-radius: 1vmin 1vmin 0vmin 0vmin !important;
        min-height: 65px !important;
        color: #fafafa;
        padding-top: 15px; }
      #chatbot-avanti .chatbot-avanti-1029 .encuestaStyles .subtitleStyle {
        margin-top: 10px !important;
        font-size: 17px !important;
        font-weight: 600 !important; }
      #chatbot-avanti .chatbot-avanti-1029 .encuestaStyles .whiteBackground {
        justify-content: center;
        margin-left: 0rem;
        margin-bottom: 10px; }
        #chatbot-avanti .chatbot-avanti-1029 .encuestaStyles .whiteBackground .MuiButton-outlinedPrimary {
          background-color: #d82200;
          border-radius: 5px;
          margin-left: 12px; }
      #chatbot-avanti .chatbot-avanti-1029 .encuestaStyles .radioButtonLabel {
        font-size: 16px !important; }
      #chatbot-avanti .chatbot-avanti-1029 .encuestaStyles .ratingStarsLabel {
        font-size: 16px !important; }
      #chatbot-avanti .chatbot-avanti-1029 .encuestaStyles .MuiFormGroup-root {
        justify-content: center !important; }
    #chatbot-avanti .chatbot-avanti-1029 .rcw-widget-container .rcw-header {
      background-color: #d82200;
      display: flex;
      justify-content: center;
      /* border-radius: 1vmin 1vmin 3vmin 3vmin; */
      padding-left: 10px;
      padding-top: 2px;
      padding-bottom: 0px;
      border-bottom: 1px solid; }
    #chatbot-avanti .chatbot-avanti-1029 .rcw-message {
      flex-direction: row;
      margin-left: 4px;
      /* 
    #chatbot-avanti .chat-closed .rcw-launcher,
    #chatbot-avanti .rcw-launcher:not(.rcw-hide-sm) {
        background-image: url('https://firebasestorage.googleapis.com/v0/b/chat-asesores-prueba.appspot.com/o/alcaldiaBogota%2Flogo_chatico%20(2).png?alt=media&token=d3e50bea-2ab0-4ea1-a253-c4ad880169eb');
        background-repeat: no-repeat;
        width: 100px;
        border-radius: 0;
        background-color: #3366cc;
        max-height: 40px;
    } */ }
      #chatbot-avanti .chatbot-avanti-1029 .rcw-message .reply-wrapper {
        border-radius: 0px 10px 10px 10px;
        background-color: #fafafa;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        width: 80%;
        max-width: 100%; }
        #chatbot-avanti .chatbot-avanti-1029 .rcw-message .reply-wrapper .options .option-item {
          background-color: transparent;
          border-color: #d82200;
          font-size: 18px;
          max-width: 100%;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; }
          #chatbot-avanti .chatbot-avanti-1029 .rcw-message .reply-wrapper .options .option-item:hover {
            background-color: #d82200;
            color: #fff; }
      #chatbot-avanti .chatbot-avanti-1029 .rcw-message .richtext-message {
        border-radius: 0px 10px 10px 10px;
        background-color: #fafafa;
        max-width: 80%;
        padding: 10px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.54) 0px 1px 3px -1px; }
        #chatbot-avanti .chatbot-avanti-1029 .rcw-message .richtext-message ol {
          font-size: 17.6px;
          margin-top: 15px; }
          #chatbot-avanti .chatbot-avanti-1029 .rcw-message .richtext-message ol li {
            font-size: 16px; }
        #chatbot-avanti .chatbot-avanti-1029 .rcw-message .richtext-message ul {
          font-size: 16px; }
        #chatbot-avanti .chatbot-avanti-1029 .rcw-message .richtext-message p a:hover {
          color: #349bf0; }
      #chatbot-avanti .chatbot-avanti-1029 .rcw-message p,
      #chatbot-avanti .chatbot-avanti-1029 .rcw-message span {
        font-size: 17.6px;
        margin-bottom: 2px;
        width: 100%;
        line-height: 20px; }
      #chatbot-avanti .chatbot-avanti-1029 .rcw-message .rcw-message-text {
        border-radius: 0px 10px 10px 10px;
        background-color: #fafafa;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        padding: 10px; }
      #chatbot-avanti .chatbot-avanti-1029 .rcw-message .panelReply {
        border-radius: 0px 10px 10px 10px;
        max-width: 86%;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; }
        #chatbot-avanti .chatbot-avanti-1029 .rcw-message .panelReply .p-panel-content {
          background-color: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
          border-radius: 0px 0px 10px 10px; }
        #chatbot-avanti .chatbot-avanti-1029 .rcw-message .panelReply .p-panel-header {
          background-color: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; }
      #chatbot-avanti .chatbot-avanti-1029 .rcw-message .rcw-client .rcw-message-text {
        background-color: #d82200;
        color: #fff;
        font-weight: bold;
        margin-right: 7px;
        border-radius: 10px 10px 0px 10px; }
      #chatbot-avanti .chatbot-avanti-1029 .rcw-message .rcw-client p {
        color: white; }
      #chatbot-avanti .chatbot-avanti-1029 .rcw-message .list-items-wrapper .grid {
        display: block; }
        #chatbot-avanti .chatbot-avanti-1029 .rcw-message .list-items-wrapper .grid .list-item {
          padding: 2px;
          margin-right: auto;
          margin-left: auto;
          width: 90%;
          max-width: 100%; }
      #chatbot-avanti .chatbot-avanti-1029 .rcw-message .list-container.list-message .list-item .option-item {
        background-color: #fff;
        color: black;
        font-weight: bold;
        border: 1px solid #d82200;
        border-radius: 12px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        font-size: 18px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; }
        #chatbot-avanti .chatbot-avanti-1029 .rcw-message .list-container.list-message .list-item .option-item:hover {
          background-color: #d82200;
          color: #fff;
          font-weight: bold; }
      #chatbot-avanti .chatbot-avanti-1029 .rcw-message img {
        margin: 0;
        margin: initial; }
    #chatbot-avanti .chatbot-avanti-1029 .rcw-widget-container .rcw-header {
      min-height: 50px; }
    @media only screen and (max-width: 800px) {
      #chatbot-avanti .chatbot-avanti-1029 .chat-closed {
        height: 0vh; } }
    #chatbot-avanti .chatbot-avanti-1029 .rcw-conversation-container .rcw-close-button {
      background-color: #d82200; }
    #chatbot-avanti .chatbot-avanti-1029 #spinnerLoading {
      position: absolute;
      z-index: 20000;
      margin-left: 80px; }
      @media only screen and (max-width: 800px) {
        #chatbot-avanti .chatbot-avanti-1029 #spinnerLoading {
          margin-left: 115px; } }
    @media only screen and (max-width: 800px) {
      #chatbot-avanti .chatbot-avanti-1029 .dfSpinnerContainer {
        bottom: 55px;
        right: 335px; } }
    #chatbot-avanti .chatbot-avanti-1029 .dfSpinnerContainer .MuiLinearProgress-barColorSecondary {
      background-color: #d82200; }
    #chatbot-avanti .chatbot-avanti-1029 .list-item .item-title {
      display: none; }
  #chatbot-avanti .chatbot-avanti-1023 .fixedPosition {
    position: fixed;
    top: 374px;
    right: 190px; }
  #chatbot-avanti .chatbot-avanti-1023 .encuestaStyles {
    border-radius: 11px 10px 10px 10px !important; }
    #chatbot-avanti .chatbot-avanti-1023 .encuestaStyles .form-title {
      border-radius: 1vmin 1vmin 0vmin 0vmin !important;
      min-height: 35px !important;
      color: #1013c2;
      padding-top: 15px; }
    #chatbot-avanti .chatbot-avanti-1023 .encuestaStyles .subtitleStyle {
      margin-top: 10px !important;
      font-size: 17px !important;
      font-weight: 600 !important; }
    #chatbot-avanti .chatbot-avanti-1023 .encuestaStyles .whiteBackground {
      justify-content: center;
      margin-left: 0rem;
      margin-bottom: 10px; }
      #chatbot-avanti .chatbot-avanti-1023 .encuestaStyles .whiteBackground .MuiButton-outlinedPrimary {
        background-color: #1013c2;
        border-radius: 5px;
        margin-left: 12px; }
    #chatbot-avanti .chatbot-avanti-1023 .encuestaStyles .radioButtonLabel {
      font-size: 16px !important; }
    #chatbot-avanti .chatbot-avanti-1023 .encuestaStyles .ratingStarsLabel {
      font-size: 16px !important; }
    #chatbot-avanti .chatbot-avanti-1023 .encuestaStyles .MuiFormGroup-root {
      justify-content: center !important; }
  #chatbot-avanti .chatbot-avanti-1023 .form_container.conversation {
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 29rem;
    height: 29rem;
    background-color: white; }
  #chatbot-avanti .chatbot-avanti-1023 .titleSubtitle {
    width: 79%;
    margin-top: 0.1rem; }
  #chatbot-avanti .chatbot-avanti-1023 .positionSpinner {
    position: fixed;
    top: 348px;
    right: 117px; }
  #chatbot-avanti .chatbot-avanti-1023 .captchaForm {
    height: 24rem; }
  @media only screen and (max-width: 800px) {
    #chatbot-avanti .chatbot-avanti-1023 .dfSpinnerContainer {
      bottom: 50px;
      right: 335px; } }
  #chatbot-avanti .chatbot-avanti-1023 .dfSpinnerContainer .MuiLinearProgress-colorSecondary {
    background-color: #a7bfeb; }
  #chatbot-avanti .chatbot-avanti-1023 .dfSpinnerContainer .MuiLinearProgress-barColorSecondary {
    background-color: #3474ec; }
  #chatbot-avanti .chatbot-avanti-1023 .iconsContainer {
    bottom: 76px !important; }
  #chatbot-avanti .chat-demo-wrapper .form-container.formInicio {
    background-color: transparent; }
    #chatbot-avanti .chat-demo-wrapper .form-container.formInicio .fake-field {
      background-color: transparent; }
    #chatbot-avanti .chat-demo-wrapper .form-container.formInicio.formCaptcha {
      box-shadow: 0 2px 10px 1px #b5b5b5;
      background-color: #fff; }
      #chatbot-avanti .chat-demo-wrapper .form-container.formInicio.formCaptcha .fake-field {
        background-color: #fff; }
  #chatbot-avanti .richtext-message a {
    color: #1778bd; }
  #chatbot-avanti .chat-widget-panel {
    position: relative; }
  #chatbot-avanti .dfSpinnerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 140px;
    right: 314px;
    z-index: 20000;
    position: fixed; }
    #chatbot-avanti .dfSpinnerContainer .df-cargando {
      font-weight: bold;
      text-shadow: 2px 2px #eee; }
    #chatbot-avanti .dfSpinnerContainer .dialogFlowSpinner {
      width: 60px;
      height: 4px; }
  #chatbot-avanti .rcwChatStyle {
    bottom: 0;
    display: flex;
    flex-direction: column;
    margin: 0 20px 20px 0;
    max-width: 370px;
    position: fixed;
    right: 0;
    width: 90vw;
    height: 77vh;
    /*   z-index: 10000; */ }
  #chatbot-avanti .chat-closed {
    width: 9vw !important; }
  #chatbot-avanti .iconsContainer {
    position: absolute;
    bottom: 68px !important;
    right: 326px; }

@media only screen and (max-width: 800px) {
  .chat-opened #chatbot-avanti .rcw-widget-container {
    height: 85vh; }
  .rcw-widget-container .rcw-launcher.rcw-hide-sm {
    display: block; }
  .chat-demo-wrapper .form-container {
    height: 85vh; }
  .rcw-conversation-container .rcw-header .rcw-close-button {
    width: 35px;
    height: 35px;
    border-radius: 23px;
    padding-top: 3px;
    top: 10px; } }
  @media only screen and (max-width: 800px) and (max-width: 800px) {
    .rcw-conversation-container .rcw-header .rcw-close-button {
      visibility: hidden; } }

@media only screen and (max-width: 800px) and (max-width: 800px) {
  .rcw-conversation-container .rcw-header {
    max-height: 80px; } }

@media only screen and (max-width: 800px) and (max-width: 800px) {
  .rcw-conversation-container {
    margin-top: 30px; } }

@media only screen and (max-width: 800px) and (max-width: 800px) {
  .chat-opened .bodyChat .rcw-widget-container,
  .chat-opened .chat-demo-wrapper .rcw-widget-container {
    height: 85vh !important; } }

@media only screen and (max-width: 800px) {
  .main-wrapper {
    z-index: 99 !important; }
  /*  .main-wrapper, :focus, :hover{
      z-index: 102;
  } */ }

.form_wrapper {
  padding: 2rem; }
  .form_wrapper .form-title {
    padding: 5px; }
    .form_wrapper .form-title .image-title {
      max-width: 140px; }
      @media only screen and (max-width: 800px) {
        .form_wrapper .form-title .image-title {
          max-height: 50px; } }
  .form_wrapper .p-rating {
    width: 100%;
    float: left;
    text-align: LEFT;
    margin-top: 2rem; }
  .form_wrapper .form_container.conversation {
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 400px; }
  .form_wrapper .helptext {
    float: left;
    margin-right: 1rem; }
  .form_wrapper .form-sub-title {
    text-align: left; }
  .form_wrapper .table .form-child li {
    position: relative; }
  .form_wrapper .table .form-child h4 {
    display: none; }
  .form_wrapper .table .form-child .button-wrapper.delete {
    position: absolute;
    top: 0;
    margin: 0; }
  .form_wrapper .table .form-child .field-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    margin: 0; }
    .form_wrapper .table .form-child .field-container .material-field {
      width: 100%;
      font-size: 1rem;
      min-height: 2rem;
      border: 1px solid #333; }
      .form_wrapper .table .form-child .field-container .material-field .label-name {
        display: none; }
      .form_wrapper .table .form-child .field-container .material-field label,
      .form_wrapper .table .form-child .field-container .material-field .p-fileupload-choose {
        font-size: 0.8rem;
        line-height: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 120px;
        font-weight: bold; }
      .form_wrapper .table .form-child .field-container .material-field .p-fileupload-choose {
        background: transparent;
        border: none;
        color: rgba(0, 0, 0, 0.54);
        font-weight: bold;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        padding-top: 24px; }

.form_container.spacer {
  min-height: 67px; }
  .form_container.spacer.last {
    min-height: 68px; }

.form_container .field-container {
  display: inline-block;
  height: auto;
  width: 100%;
  margin-bottom: 1rem; }

.form_container .label-name {
  float: left; }

.form_container .material-field {
  min-height: 6rem;
  margin-top: 1rem; }
  .form_container .material-field .MuiFormControl-root {
    width: 100%; }
    .form_container .material-field .MuiFormControl-root.datepicker {
      margin-top: 1rem; }
  .form_container .material-field.rs-100 {
    width: 100%; }
  .form_container .material-field.rs-75 {
    width: 73%;
    margin-right: 2%;
    float: left; }
  .form_container .material-field.rs-60 {
    width: 58%;
    margin-right: 2%;
    float: left; }
  .form_container .material-field.rs-50 {
    width: 48%;
    margin-right: 2%;
    float: left; }
  .form_container .material-field.rs-30 {
    width: 28%;
    margin-right: 2%;
    float: left; }
  .form_container .material-field.rs-40 {
    width: 38%;
    margin-right: 2%;
    float: left; }
  .form_container .material-field.rs-25 {
    width: 23%;
    margin-right: 2%;
    float: left; }
  .form_container .material-field.rs-20 {
    width: 18%;
    margin-right: 2%;
    float: left; }
  .form_container .material-field.rs-15 {
    width: 13%;
    margin-right: 2%;
    float: left; }
  .form_container .material-field.rs-100 .custom-input, .form_container .material-field.rs-75 .custom-input, .form_container .material-field.rs-60 .custom-input, .form_container .material-field.rs-50 .custom-input, .form_container .material-field.rs-40 .custom-input, .form_container .material-field.rs-30 .custom-input, .form_container .material-field.rs-25 .custom-input {
    flex: 0 1 100%; }
  @media only screen and (max-width: 800px) {
    .form_container .material-field.rs-100, .form_container .material-field.rs-75, .form_container .material-field.rs-60, .form_container .material-field.rs-50, .form_container .material-field.rs-40, .form_container .material-field.rs-30, .form_container .material-field.rs-25 {
      width: 100%;
      margin-right: 0;
      float: left; } }

.button-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1rem; }
  .button-wrapper .MuiButton-outlinedPrimary {
    color: white;
    background-color: #3f51b5;
    border: none; }
    .button-wrapper .MuiButton-outlinedPrimary:hover {
      background-color: #b6b6b6;
      border: none; }
    .button-wrapper .MuiButton-outlinedPrimary[disabled=disabled], .button-wrapper .MuiButton-outlinedPrimary:disabled {
      background-color: #ececec;
      color: #23232e; }
  .button-wrapper .MuiButton-outlinedSecondary {
    color: white;
    background-color: #23232e;
    border: none; }
    .button-wrapper .MuiButton-outlinedSecondary:hover {
      background-color: #555558;
      border: none; }
    .button-wrapper .MuiButton-outlinedSecondary[disabled=disabled], .button-wrapper .MuiButton-outlinedSecondary:disabled {
      background-color: #ececec;
      color: #23232e; }
  .button-wrapper.delete {
    justify-content: flex-end; }

.section-container {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 1rem;
  margin-bottom: 1rem; }

.form-child {
  display: flex;
  width: 100%;
  flex-direction: column;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 1rem; }
  .form-child h4 {
    text-align: left; }

.p-fileupload {
  display: flex; }

.p-button.p-fileupload-choose {
  background-color: #3f51b5;
  border: 1px solid #3f51b5; }

.form-tooltip {
  float: left;
  margin-right: 0.5rem; }

.no-visible {
  visibility: hidden !important; }


.side-nav-wrapper {
  position: fixed;
  /* Fixed Sidebar (stay in place on scroll and position relative to viewport) */
  width: 5rem;
  height: 100vh;
  background-color: #23232e;
  transition: width 200ms ease;
  z-index: 2; }
  .side-nav-wrapper .side-nav-nar {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%; }
    .side-nav-wrapper .side-nav-nar .logo {
      text-align: center;
      color: #ececec;
      background-color: #141418;
      text-transform: uppercase;
      letter-spacing: 0.3ch;
      width: 100%; }
      .side-nav-wrapper .side-nav-nar .logo .link-text {
        margin-left: 1rem;
        font-weight: bold;
        font-size: 1.5rem; }
      .side-nav-wrapper .side-nav-nar .logo .nav-icon {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        transition: -webkit-transform 600ms;
        transition: transform 600ms;
        transition: transform 600ms, -webkit-transform 600ms; }
    .side-nav-wrapper .side-nav-nar:hover .logo .nav-icon {
      -webkit-transform: rotate(-180deg);
              transform: rotate(-180deg); }
  .side-nav-wrapper:hover {
    width: 20rem; }
    .side-nav-wrapper:hover .side-nav-nar .link-text {
      display: block; }
  @media only screen and (min-width: 900px) {
    .side-nav-wrapper {
      top: 0;
      width: 5rem;
      height: 100vh; }
      .side-nav-wrapper:hover {
        width: 20rem; }
        .side-nav-wrapper:hover .link-text {
          display: inline; } }
  @media only screen and (max-width: 800px) {
    .side-nav-wrapper {
      bottom: 0;
      width: 100vw;
      height: 5rem;
      z-index: 2; }
      .side-nav-wrapper .logo {
        display: none; }
      .side-nav-wrapper .side-nav-nar {
        flex-direction: row;
        justify-content: space-around; }
      .side-nav-wrapper .sidebar-nav-link {
        justify-content: center; } }

.styled-nav-item {
  width: 100%;
  /* width must be same size as NavBar to center */
  text-align: center;
  /* Aligns <a> inside of NavIcon div */ }
  .styled-nav-item:hover > .submenu {
    display: block;
    transition: all 2s linear; }
  .styled-nav-item .nav-link {
    padding: 0; }
  .styled-nav-item:hover {
    background: #141418;
    color: #ff7eee; }
  .styled-nav-item:last-child {
    margin-top: auto; }
  .styled-nav-item .sidebar-nav-link {
    display: flex;
    align-items: center;
    height: 5rem;
    width: 16rem;
    color: #6649b8;
    text-decoration: none;
    -webkit-filter: grayscale(100%) opacity(0.7);
            filter: grayscale(100%) opacity(0.7);
    transition: 600ms; }
    .styled-nav-item .sidebar-nav-link:hover {
      text-decoration: none;
      -webkit-filter: none;
              filter: none; }
    .styled-nav-item .sidebar-nav-link .link-text {
      display: none;
      margin-left: 0;
      font-weight: 500;
      font-size: 1.2rem; }
    .styled-nav-item .sidebar-nav-link .nav-icon,
    .styled-nav-item .sidebar-nav-link .nav-image {
      min-width: 2rem;
      margin: 0 1.5rem;
      height: 2rem;
      color: #6649b8;
      transition: 600ms; }
      .styled-nav-item .sidebar-nav-link .nav-icon:hover,
      .styled-nav-item .sidebar-nav-link .nav-image:hover {
        color: #6649b8; }
    @media only screen and (max-width: 800px) {
      .styled-nav-item .sidebar-nav-link {
        width: auto; } }
  @media only screen and (max-width: 800px) {
    .styled-nav-item {
      width: auto; } }
  .styled-nav-item .submenu {
    display: none;
    transition: all 2s linear;
    padding-left: 0; }
    @media only screen and (max-width: 800px) {
      .styled-nav-item .submenu {
        display: none; } }
    .styled-nav-item .submenu .styled-nav-item .sidebar-nav-link {
      height: 3rem;
      border-bottom: 1px solid #23232e; }
      .styled-nav-item .submenu .styled-nav-item .sidebar-nav-link .link-text {
        font-size: 1rem; }
      .styled-nav-item .submenu .styled-nav-item .sidebar-nav-link .nav-icon {
        max-width: 1.5rem;
        min-width: 1.5rem; }
    .styled-nav-item .submenu .submenu-nav-link {
      width: 20rem;
      background: #574b90;
      color: #eee;
      text-align: left;
      border-bottom: 1px solid #000;
      font-weight: 500;
      padding: 10px 0 10px 25px;
      -webkit-filter: grayscale(30%) opacity(0.7);
              filter: grayscale(30%) opacity(0.7); }
      .styled-nav-item .submenu .submenu-nav-link:hover {
        -webkit-filter: grayscale(0%) opacity(1);
                filter: grayscale(0%) opacity(1); }

