@import url("https://fonts.googleapis.com/css2?family=Abel&family=Roboto:wght@100&display=swap");
#chatbot-avanti .chatbot-avanti :root {
  font-size: 12px; }

#chatbot-avanti html {
  background-color: transparent; }

#chatbot-avanti .hidden {
  display: none !important; }

#chatbot-avanti .bodyChat {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  background-color: transparent;
  padding: 0; }
  #chatbot-avanti .bodyChat::-webkit-scrollbar {
    width: 0.25rem; }
    #chatbot-avanti .bodyChat::-webkit-scrollbar-track {
      background: #1e1e24; }
    #chatbot-avanti .bodyChat::-webkit-scrollbar-thumb {
      background: #6649b8; }

#chatbot-avanti .main-wrapper {
  font-family: 'Abel', sans-serif;
  font-weight: 500; }
  #chatbot-avanti .main-wrapper h1 {
    text-align: center; }
  #chatbot-avanti .main-wrapper .cards-wrapper .card {
    box-shadow: 0 0 20px 4px #555558; }
  #chatbot-avanti .main-wrapper .card,
  #chatbot-avanti .main-wrapper .table-responsive {
    box-shadow: 0 0 40px 4px #111118; }
  #chatbot-avanti .main-wrapper button.btn-primary,
  #chatbot-avanti .main-wrapper a.btn-primary {
    background-color: #574b90;
    border: solid 1px #6649b8; }
    #chatbot-avanti .main-wrapper button.btn-primary:hover,
    #chatbot-avanti .main-wrapper a.btn-primary:hover {
      filter: grayscale(30%) opacity(0.7); }

#chatbot-avanti .copy-right {
  position: fixed;
  width: 130px;
  right: 20px;
  bottom: 5px;
  text-align: center;
  padding: 6px 10px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.1);
  font-size: 0.6rem;
  font-weight: bold;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  border: 1px solid #bbb;
  color: #eee;
  text-shadow: 0px 1px 1px black; }

#chatbot-avanti code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

@media only screen and (min-width: 600px) {
  #chatbot-avanti .desktop-only {
    display: block !important; } }

@media only screen and (max-width: 800px) {
  #chatbot-avanti .desktop-only {
    display: none !important; } }

@media only screen and (min-width: 600px) {
  #chatbot-avanti .mobile-only {
    display: none !important; } }

@media only screen and (max-width: 800px) {
  #chatbot-avanti .mobile-only {
    display: block !important; } }

#chatbot-avanti ul {
  list-style-type: disc; }

#chatbot-avanti .form-custom-group {
  display: flex;
  margin-bottom: 1rem;
  width: 100%; }
  #chatbot-avanti .form-custom-group .label-name {
    flex: 20% 0; }
  #chatbot-avanti .form-custom-group .custom-input {
    flex: 0 60%; }

#chatbot-avanti div.form-custom-group-i {
  width: 50%;
  display: block;
  margin-bottom: 1rem;
  height: 3.5rem; }
  #chatbot-avanti div.form-custom-group-i .custom-input.form-control {
    width: 100%;
    height: 100%;
    padding-top: 1rem;
    border: none; }
    #chatbot-avanti div.form-custom-group-i .custom-input.form-control:focus {
      z-index: 0; }
  #chatbot-avanti div.form-custom-group-i .label-name {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 2;
    margin: 0;
    pointer-events: none;
    border-bottom: 1px solid black;
    font-size: 1rem;
    font-weight: 500; }
    #chatbot-avanti div.form-custom-group-i .label-name::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      height: 100%;
      width: 100%;
      border-bottom: 2px solid #5fa8d3; }

#chatbot-avanti .main-wrapper h1 {
  margin-top: 3rem; }

#chatbot-avanti .alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  #chatbot-avanti .alert.danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb; }
  #chatbot-avanti .alert.success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb; }
